require('./bootstrap');
import 'selectize';
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-fixedcolumns-bs4';
import 'datatables.net-fixedheader-bs4';
import 'datatables.net-responsive-bs4';
import 'datatables.net-searchbuilder-bs4';
import 'datatables.net-datetime';
import 'datatables.net-scroller-bs4';
import 'datatables.net-rowgroup-bs4';
import 'datatables.net-buttons-bs4';
import jsZip from 'jszip';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import 'datatables.net-buttons/js/buttons.print.min';
import 'holderjs';
import 'select2';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import download from 'downloadjs';
import L from 'leaflet';
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

window.JSZip = jsZip;
window.ClipboardJS = require('clipboard');
window.PDFDocument = PDFDocument;
window.StandardFonts = StandardFonts;
window.rgb = rgb;
window.download = download;
window.L = L;
window.Chart = Chart;

Chart.register(ChartDataLabels);